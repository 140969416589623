import { INIT } from "./action-types";
import {
  SET_ENTITIES,
  SET_IS_INITIALIZED,
  SET_IS_LOADING
} from "./mutation-types";

export default apiModuleFunction => ({
  async [INIT]({ state, commit }) {
    commit(SET_IS_LOADING, true);
    const filters = state.filters;
    const dataPoints = await apiModuleFunction({ filters });
    commit(SET_ENTITIES, dataPoints);
    if (!state.isInitialized) {
      commit(SET_IS_INITIALIZED, false);
    }
    commit(SET_IS_LOADING, false);
    return dataPoints;
  }
});
